<template>
  <header class="sticky bg-blue-500">
    <div
      class="flex flex-wrap items-center justify-between w-full p-3 shadow-md"
    >
      <div class="flex items-center">
        <span
          class="text-lg text-left text-center font-bold text-white w-48 md:w-full"
          >E-Learning Part Timer Indomaret Group</span
        >
      </div>
      <div class="flex items-center">
        <img
          src="../../assets/logo.png"
          color="#fff"
          class="h-10 w-24 text-white mr-4"
        />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>
